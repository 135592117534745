import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "but-what-if"
    }}>{`But what if…`}</h1>
    <h3 {...{
      "id": "my-teenager-takes-a-long-time-to-fall-asleep"
    }}>{`My teenager takes a long time to fall asleep?`}</h3>
    <p>{`Encourage your teenager to wind down with relaxing activities and avoid stimulating activities, such as moderate exercise, computer games, television and important discussions in the hour before bedtime. Relaxing activities include reading, listening to music, meditation, or having a warm bath or shower.`}</p>
    <p>{`If your teenager finds it really hard to fall asleep, suggest that they get out of bed and do something relaxing in another room. They should go back to bed when they’re feeling drowsy.`}</p>
    <h3 {...{
      "id": "my-teenager-cant-wake-up-on-time-for-school"
    }}>{`My teenager can’t wake up on time for school?`}</h3>
    <p>{`In addition to following all the good sleep habits, see if going to bed earlier helps.`}</p>
    <h3 {...{
      "id": "my-teenager-sleeps-till-midday-on-the-weekend"
    }}>{`My teenager sleeps till midday on the weekend?`}</h3>
    <p>{`Try to encourage your teenager to go to bed and get up at the same time each day (even on weekends). It’s common for teenagers to go to bed later, and then sleep in on weekends. This can be okay, if they don’t change their weekend bedtimes by any more than 2 hours.`}</p>
    <h3 {...{
      "id": "my-teenager-still-feels-tired-even-after-a-full-nights-sleep"
    }}>{`My teenager still feels tired even after a full night’s sleep?`}</h3>
    <p>{`Often feeling tired after a full night’s sleep can be a sign of other problems. We suggest that you take your teenager to your family GP.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/35.svg" alt="Playing soccer" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      